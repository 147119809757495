import {TEF} from '../services/resources';

const state = {};

const mutations = {};

const actions = {
    startBilling(context, data) {
        return TEF.startBilling(data).then((response) => {
            return response.data;
        });
    },
    refundBilling(context, data) {
        return TEF.refundBilling(data).then((response) => {
            return response.data;
        });
    },
};

const module = {
    state,
    mutations,
    actions
};

export default module;
