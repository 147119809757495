import Vue from 'vue'
import Vuex from 'vuex'
import auth from "@/store/auth";
import checks from "@/store/checks";
import dashboard from "@/store/dashboard";
import config from "@/store/config";
import customers from "@/store/customers";
import products from "@/store/products";
import categories from "@/store/categories";
import users from "@/store/users";
import companies from "@/store/companies";
import purchases from "@/store/purchases";
import analytics from "@/store/analytics";
import carts from "@/store/carts";
import nfe from "@/store/nfe";
import supplyOrders from "@/store/supply-orders";
import slips from "@/store/slips";
import communications from "@/store/communications";
import tickets from "@/store/tickets";
import departments from "@/store/departments";
import slipSchedules from "@/store/slip-schedules";
import tags from "@/store/tags";
import sales from "@/store/sales";
import nfce from "@/store/nfce";
import cashiers from "@/store/cashiers";
import employees from "@/store/employees";
import duties from "@/store/duties";
import nfeSupply from "@/store/nfe-supply";
import suppliers from "@/store/suppliers";
import accountPlans from "@/store/account-plans";
import bankAccounts from "@/store/bank-accounts";
import paymentTypes from "@/store/payment-types";
import accountsPayable from "@/store/accounts-payable";
import accountsReceivable from "@/store/accounts-receivable";
import cashFlow from "@/store/cash-flow";
import franchiseCashier from "@/store/franchise-cashier";
import bankAccountMovements from "@/store/bank-account-movements";
import bankAccountPendingMovements from "@/store/bank-account-pending-movements";
import nfse from "@/store/nfse";
import tef from "@/store/tef";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        auth,
        checks,
        dashboard,
        config,
        customers,
        products,
        categories,
        users,
        companies,
        purchases,
        analytics,
        carts,
        nfe,
        supplyOrders,
        slips,
        communications,
        tickets,
        departments,
        slipSchedules,
        tags,
        sales,
        nfce,
        cashiers,
        employees,
        duties,
        nfeSupply,
        suppliers,
        accountPlans,
        bankAccounts,
        paymentTypes,
        accountsPayable,
        accountsReceivable,
        cashFlow,
        franchiseCashier,
        bankAccountMovements,
        bankAccountPendingMovements,
        nfse,
        tef
    }
});
