import Vue from 'vue'
import VueResource from 'vue-resource'
import './interceptors'

Vue.use(VueResource);

export class Jwt {
    static dependenciesServiceAuth = {};

    static accessToken(email, password) {
        let link = 'api/token';

        return Vue.http.post(link, Object.assign(this.dependenciesServiceAuth, {
            'email': email,
            'password': password
        }));
    }

    static register(fields) {
        return Vue.http.post('api/oauth/register', fields);
    }

    static logout() {
        return Vue.http.post('api/logout');
    }

    static refreshToken(token) {
        /**
         * rever esta função
         */
        return Vue.http.post('oauth/token', Object.assign(this.dependenciesServiceAuth, {
            'grant_type': 'refresh_token',
            'refresh_token': token
        }));
    }

    static sendResetPassword(fields) {
        return Vue.http.post('api/password/email', fields);
    }

    static resetPassword(fields) {
        return Vue.http.post('api/password/reset', fields);
    }

    static authUser() {
        return Vue.http.get('api/auth-user');
    }
}

let Check = 'api/checks';
Check = Vue.resource({}, {}, {
    getAPPVersion: {method: 'GET', url: `${Check}/version`},
});

let Dashboard = 'api/dashboard';
Dashboard = Vue.resource(`${Dashboard}`, {}, {
    getDashboard: {method: 'GET', url: `${Dashboard}/get`},
    updateImagesDashboard: {method: 'POST', url: `${Dashboard}/updateImagesDashboard`},
    updateDashboardConfig: {method: 'POST', url: `${Dashboard}/updateDashboardConfig`},
});

let Config = 'api/config';
Config = Vue.resource(`${Config}`, {}, {
    getConfig: {method: 'GET', url: `${Config}/show`},
    update: {method: 'POST', url: `${Config}/update`},
});

let Customers = 'api/customers';
Customers = Vue.resource(`${Customers}{/id}`, {}, {
    getCustomers: {method: 'GET', url: `${Customers}/list`},
    create: {method: 'POST', url: `${Customers}/create`},
    update: {method: 'POST', url: `${Customers}/update`},
    destroy: {method: 'POST', url: `${Customers}/destroy`},
    getCustomerField: {method: 'POST', url: `${Customers}/getCustomerField`},
    getCustomerBalanceHistories: {method: 'POST', url: `${Customers}/getCustomerBalanceHistories`},
    updateCustomerBalance: {method: 'POST', url: `${Customers}/updateCustomerBalance`},

    createCustomerPublic: {method: 'POST', url: `${Customers}/createCustomerPublic`},
    checkCustomerPublic: {method: 'POST', url: `${Customers}/checkCustomerPublic`},
});

let Products = 'api/products';
Products = Vue.resource(`${Products}{/id}`, {}, {
    getProducts: {method: 'GET', url: `${Products}/list`},
    create: {method: 'POST', url: `${Products}/create`},
    update: {method: 'POST', url: `${Products}/update`},
    destroy: {method: 'POST', url: `${Products}/destroy`},
    getProductField: {method: 'POST', url: `${Products}/getProductField`},
    getProductBalanceHistories: {method: 'POST', url: `${Products}/getProductBalanceHistories`},
});

let Category = 'api/categories';
Category = Vue.resource(`${Category}{/id}`, {}, {
    getCategories: {method: 'GET', url: `${Category}/list`},
    create: {method: 'POST', url: `${Category}/create`},
    update: {method: 'POST', url: `${Category}/update`},
    destroy: {method: 'POST', url: `${Category}/destroy`},
    getCategoryField: {method: 'POST', url: `${Category}/getCategoryField`},
});

let Users = 'api/users';
Users = Vue.resource(`${Users}{/id}`, {}, {
    getUsers: {method: 'GET', url: `${Users}/list`},
    create: {method: 'POST', url: `${Users}/create`},
    update: {method: 'POST', url: `${Users}/update`},
    destroy: {method: 'POST', url: `${Users}/destroy`},
    getUserField: {method: 'POST', url: `${Users}/getUserField`},
    getUserBadge: {method: 'POST', url: `${Users}/getUserBadge`},
    getUserNotifications: {method: 'GET', url: `${Users}/notifications`},
});

let Companies = 'api/companies';
Companies = Vue.resource(`${Companies}{/id}`, {}, {
    getCompanies: {method: 'GET', url: `${Companies}/list`},
    create: {method: 'POST', url: `${Companies}/create`},
    update: {method: 'POST', url: `${Companies}/update`},
    destroy: {method: 'POST', url: `${Companies}/destroy`},
    getCompanyField: {method: 'POST', url: `${Companies}/getCompanyField`},

    getCompanyFieldPublic: {method: 'POST', url: `${Companies}/getCompanyFieldPublic`},
});

let Purchases = 'api/purchases';
Purchases = Vue.resource(`${Purchases}{/id}`, {}, {
    getPurchases: {method: 'GET', url: `${Purchases}/list`},
    create: {method: 'POST', url: `${Purchases}/create`},
    update: {method: 'POST', url: `${Purchases}/update`},
    // destroy: {method: 'POST', url: `${Purchases}/destroy`},
    getPurchaseStatistics: {method: 'POST', url: `${Purchases}/getPurchaseStatistics`},
    sendEmailReceiptPurchase: {method: 'POST', url: `${Purchases}/sendEmailReceiptPurchase`},
    cancelPurchase: {method: 'POST', url: `${Purchases}/cancelPurchase`},
    signPurchase: {method: 'POST', url: `${Purchases}/signPurchase`},
    changePaymentMethods: {method: 'POST', url: `${Purchases}/changePaymentMethods`},
});

let Analytics = 'api/analytics';
Analytics = Vue.resource(`${Analytics}{/id}`, {}, {
    getAnalytics: {method: 'GET', url: `${Analytics}/get`},
    getSaleAnalytics: {method: 'GET', url: `${Analytics}/sales/get`},
});

let Carts = 'api/carts';
Carts = Vue.resource(`${Carts}{/id}`, {}, {
    getCarts: {method: 'GET', url: `${Carts}/list`},
    create: {method: 'POST', url: `${Carts}/create`},
    update: {method: 'POST', url: `${Carts}/update`},
    destroy: {method: 'POST', url: `${Carts}/destroy`},
    countCart: {method: 'POST', url: `${Carts}/countCart`},
    getListAvailableTimes: {method: 'POST', url: `${Carts}/getListAvailableTimes`},
    cartSaleDraft: {method: 'POST', url: `${Carts}/cartSaleDraft`},

    createCartPublic: {method: 'POST', url: `${Carts}/createCartPublic`},
    getListAvailableTimesPublic: {method: 'POST', url: `${Carts}/getListAvailableTimesPublic`},
    cancelCartPublic: {method: 'POST', url: `${Carts}/cancelCartPublic`},
});

let NFe = 'api/nfe';
NFe = Vue.resource(`${NFe}{/id}`, {}, {
    update: {method: 'POST', url: `${NFe}/update`},
    generateDANFe: {method: 'POST', url: `${NFe}/generateDANFe`},
    issueNFe: {method: 'POST', url: `${NFe}/issueNFe`},
    cancelNFe: {method: 'POST', url: `${NFe}/cancelNFe`},
    listNFeInutilizadas: {method: 'GET', url: `${NFe}/listNFeInutilizadas`},
    inutilizaNFe: {method: 'POST', url: `${NFe}/inutilizaNFe`},
});

let SupplyOrders = 'api/supply-orders';
SupplyOrders = Vue.resource(`${SupplyOrders}{/id}`, {}, {
    getSupplyOrders: {method: 'GET', url: `${SupplyOrders}/list`},
    create: {method: 'POST', url: `${SupplyOrders}/create`},
    update: {method: 'POST', url: `${SupplyOrders}/update`},
    destroy: {method: 'POST', url: `${SupplyOrders}/destroy`},
    cancelSupplyOrder: {method: 'POST', url: `${SupplyOrders}/cancelSupplyOrder`},
});

let Slips = 'api/slips';
Slips = Vue.resource(`${Slips}{/id}`, {}, {
    getSlips: {method: 'GET', url: `${Slips}/list`},
    create: {method: 'POST', url: `${Slips}/create`},
    update: {method: 'POST', url: `${Slips}/update`},
    destroy: {method: 'POST', url: `${Slips}/destroy`},
    getSlipStatistics: {method: 'POST', url: `${Slips}/getSlipStatistics`},
});

let Communications = 'api/communications';
Communications = Vue.resource(`${Communications}{/id}`, {}, {
    getCommunications: {method: 'GET', url: `${Communications}/list`},
    create: {method: 'POST', url: `${Communications}/create`},
    update: {method: 'POST', url: `${Communications}/update`},
    destroy: {method: 'POST', url: `${Communications}/destroy`},
    getCompanyCommunication: {method: 'POST', url: `${Communications}/getCompanyCommunication`},
    setCompanyCommunication: {method: 'POST', url: `${Communications}/setCompanyCommunication`},
});

let Tickets = 'api/tickets';
Tickets = Vue.resource(`${Tickets}{/id}`, {}, {
    getTickets: {method: 'GET', url: `${Tickets}/list`},
    create: {method: 'POST', url: `${Tickets}/create`},
    update: {method: 'POST', url: `${Tickets}/update`},
    destroy: {method: 'POST', url: `${Tickets}/destroy`},
    getTicketField: {method: 'POST', url: `${Tickets}/getTicketField`},
    updateTicketStatus: {method: 'POST', url: `${Tickets}/updateTicketStatus`},
});

let Departments = 'api/departments';
Departments = Vue.resource(`${Departments}{/id}`, {}, {
    getDepartments: {method: 'GET', url: `${Departments}/list`},
    create: {method: 'POST', url: `${Departments}/create`},
    update: {method: 'POST', url: `${Departments}/update`},
    destroy: {method: 'POST', url: `${Departments}/destroy`},
    getDepartmentField: {method: 'POST', url: `${Departments}/getDepartmentField`},
    getDepartmentServiceField: {method: 'POST', url: `${Departments}/getDepartmentServiceField`},
});

let SlipSchedules = 'api/slip-schedules';
SlipSchedules = Vue.resource(`${SlipSchedules}{/id}`, {}, {
    getSlipSchedules: {method: 'GET', url: `${SlipSchedules}/list`},
    create: {method: 'POST', url: `${SlipSchedules}/create`},
    update: {method: 'POST', url: `${SlipSchedules}/update`},
    destroy: {method: 'POST', url: `${SlipSchedules}/destroy`},
});

let Tags = 'api/tags';
Tags = Vue.resource(`${Tags}{/id}`, {}, {
    getTags: {method: 'GET', url: `${Tags}/list`},
    create: {method: 'POST', url: `${Tags}/create`},
    update: {method: 'POST', url: `${Tags}/update`},
    destroy: {method: 'POST', url: `${Tags}/destroy`},
    getTagField: {method: 'POST', url: `${Tags}/getTagField`},
});

let Sales = 'api/sales';
Sales = Vue.resource(`${Sales}{/id}`, {}, {
    getSales: {method: 'GET', url: `${Sales}/list`},
    create: {method: 'POST', url: `${Sales}/create`},
    update: {method: 'POST', url: `${Sales}/update`},
    cancelSale: {method: 'POST', url: `${Sales}/cancelSale`},
    sendEmailSale: {method: 'POST', url: `${Sales}/sendEmailSale`},
    getSaleStatistics: {method: 'POST', url: `${Sales}/getSaleStatistics`},
});

let NFCe = 'api/nfce';
NFCe = Vue.resource(`${NFCe}{/id}`, {}, {
    update: {method: 'POST', url: `${NFCe}/update`},
    generateDANFCe: {method: 'POST', url: `${NFCe}/generateDANFCe`},
    issueNFCe: {method: 'POST', url: `${NFCe}/issueNFCe`},
    cancelNFCe: {method: 'POST', url: `${NFCe}/cancelNFCe`},
    listNFCeInutilizadas: {method: 'GET', url: `${NFCe}/listNFCeInutilizadas`},
    inutilizaNFCe: {method: 'POST', url: `${NFCe}/inutilizaNFCe`},
    generateCartReturn: {method: 'POST', url: `${NFCe}/generateCartReturn`},
});

let Cashiers = 'api/cashiers';
Cashiers = Vue.resource(`${Cashiers}{/id}`, {}, {
    getCashiers: {method: 'GET', url: `${Cashiers}/list`},
    create: {method: 'POST', url: `${Cashiers}/create`},
    update: {method: 'POST', url: `${Cashiers}/update`},
    destroy: {method: 'POST', url: `${Cashiers}/destroy`},
    getUserCashier: {method: 'POST', url: `${Cashiers}/getUserCashier`},
    createSuprimentoCashier: {method: 'POST', url: `${Cashiers}/createSuprimentoCashier`},
    createSangriaCashier: {method: 'POST', url: `${Cashiers}/createSangriaCashier`},
    createFechamentoCashier: {method: 'POST', url: `${Cashiers}/createFechamentoCashier`},
});

let Employees = 'api/employees';
Employees = Vue.resource(`${Employees}{/id}`, {}, {
    getEmployees: {method: 'GET', url: `${Employees}/list`},
    create: {method: 'POST', url: `${Employees}/create`},
    update: {method: 'POST', url: `${Employees}/update`},
    destroy: {method: 'POST', url: `${Employees}/destroy`},
    getEmployeeField: {method: 'POST', url: `${Employees}/getEmployeeField`},
});

let Duty = 'api/duties';
Duty = Vue.resource(`${Duty}{/id}`, {}, {
    getDuties: {method: 'GET', url: `${Duty}/list`},
    create: {method: 'POST', url: `${Duty}/create`},
    update: {method: 'POST', url: `${Duty}/update`},
    destroy: {method: 'POST', url: `${Duty}/destroy`},
    getDutyField: {method: 'POST', url: `${Duty}/getDutyField`},
});

let NFeSupply = 'api/nfe-supply';
NFeSupply = Vue.resource(`${NFeSupply}{/id}`, {}, {
    update: {method: 'POST', url: `${NFeSupply}/update`},
    generateDANFeSupply: {method: 'POST', url: `${NFeSupply}/generateDANFe`},
    issueNFeSupply: {method: 'POST', url: `${NFeSupply}/issueNFe`},
    cancelNFeSupply: {method: 'POST', url: `${NFeSupply}/cancelNFe`},
});

let Suppliers = 'api/suppliers';
Suppliers = Vue.resource(`${Suppliers}{/id}`, {}, {
    getSuppliers: {method: 'GET', url: `${Suppliers}/list`},
    create: {method: 'POST', url: `${Suppliers}/create`},
    update: {method: 'POST', url: `${Suppliers}/update`},
    destroy: {method: 'POST', url: `${Suppliers}/destroy`},
    getSupplierField: {method: 'POST', url: `${Suppliers}/getSupplierField`},
});

let AccountPlans = 'api/account-plans';
AccountPlans = Vue.resource(`${AccountPlans}{/id}`, {}, {
    getDefinitionAccountPlan: {method: 'GET', url: `${AccountPlans}/getDefinitionAccountPlan`},
    getAccountPlans: {method: 'GET', url: `${AccountPlans}/list`},
    create: {method: 'POST', url: `${AccountPlans}/create`},
    update: {method: 'POST', url: `${AccountPlans}/update`},
    destroy: {method: 'POST', url: `${AccountPlans}/destroy`},
    getAccountPlanField: {method: 'POST', url: `${AccountPlans}/getAccountPlanField`},
    getAccountPlanItemField: {method: 'POST', url: `${AccountPlans}/getAccountPlanItemField`},
    updateDefinitionAccountPlan: {method: 'POST', url: `${AccountPlans}/updateDefinitionAccountPlan`},
});

let BankAccounts = 'api/bank-accounts';
BankAccounts = Vue.resource(`${BankAccounts}{/id}`, {}, {
    getBankAccounts: {method: 'GET', url: `${BankAccounts}/list`},
    create: {method: 'POST', url: `${BankAccounts}/create`},
    update: {method: 'POST', url: `${BankAccounts}/update`},
    destroy: {method: 'POST', url: `${BankAccounts}/destroy`},
    getBankAccountField: {method: 'POST', url: `${BankAccounts}/getBankAccountField`},
    getBankField: {method: 'POST', url: `${BankAccounts}/getBankField`},
});

let PaymentTypes = 'api/payment-types';
PaymentTypes = Vue.resource(`${PaymentTypes}{/id}`, {}, {
    getPaymentTypes: {method: 'GET', url: `${PaymentTypes}/list`},
    create: {method: 'POST', url: `${PaymentTypes}/create`},
    update: {method: 'POST', url: `${PaymentTypes}/update`},
    destroy: {method: 'POST', url: `${PaymentTypes}/destroy`},
    getPaymentTypeField: {method: 'POST', url: `${PaymentTypes}/getPaymentTypeField`},
});

let AccountsPayable = 'api/accounts-payable';
AccountsPayable = Vue.resource(`${AccountsPayable}{/id}`, {}, {
    getAccountsPayable: {method: 'GET', url: `${AccountsPayable}/list`},
    create: {method: 'POST', url: `${AccountsPayable}/create`},
    update: {method: 'POST', url: `${AccountsPayable}/update`},
    destroy: {method: 'POST', url: `${AccountsPayable}/destroy`},
    paymentAccountPayable: {method: 'POST', url: `${AccountsPayable}/paymentAccountPayable`},
    cancelAccountPayable: {method: 'POST', url: `${AccountsPayable}/cancelAccountPayable`},
    reverseAccountPayable: {method: 'POST', url: `${AccountsPayable}/reverseAccountPayable`},
});

let AccountsReceivable = 'api/accounts-receivable';
AccountsReceivable = Vue.resource(`${AccountsReceivable}{/id}`, {}, {
    getAccountsReceivable: {method: 'GET', url: `${AccountsReceivable}/list`},
    create: {method: 'POST', url: `${AccountsReceivable}/create`},
    update: {method: 'POST', url: `${AccountsReceivable}/update`},
    destroy: {method: 'POST', url: `${AccountsReceivable}/destroy`},
    paymentAccountReceivable: {method: 'POST', url: `${AccountsReceivable}/paymentAccountReceivable`},
    cancelAccountReceivable: {method: 'POST', url: `${AccountsReceivable}/cancelAccountReceivable`},
    reverseAccountReceivable: {method: 'POST', url: `${AccountsReceivable}/reverseAccountReceivable`},
});

let CashFlow = 'api/cash-flow';
CashFlow = Vue.resource(`${CashFlow}{/id}`, {}, {
    getDailyCashFlow: {method: 'POST', url: `${CashFlow}/getDailyCashFlow`},
    showDailyCashFlow: {method: 'POST', url: `${CashFlow}/showDailyCashFlow`},
    getMonthlyCashFlow: {method: 'POST', url: `${CashFlow}/getMonthlyCashFlow`},
    showMonthlyCashFlow: {method: 'POST', url: `${CashFlow}/showMonthlyCashFlow`},
    getCompaniesMonthlyCashFlow: {method: 'POST', url: `${CashFlow}/getCompaniesMonthlyCashFlow`},
});

let FranchiseCashiers = 'api/franchise-cashier';
FranchiseCashiers = Vue.resource({}, {}, {
    getFranchiseCashierMovements: {method: 'GET', url: `${FranchiseCashiers}/movements`},
    create: {method: 'POST', url: `${FranchiseCashiers}/create`},
});

let BankAccountDates = 'api/bank-account-date';
BankAccountDates = Vue.resource({}, {}, {
    getBankAccountDateMovements: {method: 'GET', url: `${BankAccountDates}/movements`},
    create: {method: 'POST', url: `${BankAccountDates}/create`},
});

let BankAccountPendingMovements = 'api/bank-account-pending';
BankAccountPendingMovements = Vue.resource({}, {}, {
    getBankAccountPendingMovements: {method: 'GET', url: `${BankAccountPendingMovements}/movements`},
});

let NFSe = 'api/nfse';
NFSe = Vue.resource(`${NFSe}{/id}`, {}, {
    update: {method: 'POST', url: `${NFSe}/update`},
    generateDANFSe: {method: 'POST', url: `${NFSe}/generateDANFSe`},
    issueNFSe: {method: 'POST', url: `${NFSe}/issueNFSe`},
    cancelNFSe: {method: 'POST', url: `${NFSe}/cancelNFSe`},
    getLayoutNFSeField: {method: 'POST', url: `${NFSe}/getLayoutNFSeField`},
});

let TEF = 'api/tef';
TEF = Vue.resource({}, {}, {
    startBilling: {method: 'POST', url: `${TEF}/startBilling`},
    refundBilling: {method: 'POST', url: `${TEF}/refundBilling`},
});

export {
    Check,
    Dashboard,
    Config,
    Customers,
    Products,
    Category,
    Users,
    Companies,
    Purchases,
    Analytics,
    Carts,
    NFe,
    SupplyOrders,
    Slips,
    Communications,
    Tickets,
    Departments,
    SlipSchedules,
    Tags,
    Sales,
    NFCe,
    Cashiers,
    Employees,
    Duty,
    NFeSupply,
    Suppliers,
    AccountPlans,
    BankAccounts,
    PaymentTypes,
    AccountsPayable,
    AccountsReceivable,
    CashFlow,
    FranchiseCashiers,
    BankAccountDates,
    BankAccountPendingMovements,
    NFSe,
    TEF
};
